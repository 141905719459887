@for $i from 4 through 6 {
  .chessBoard-#{$i} {
    grid-template-columns: repeat($i, auto);
  }
}

.chessBoard {
  user-select: none;
  display: grid;
  gap: 1px;
  background-color: #000;
  border: 2px solid var(--font-color);
}

.chessBoard-row {
  display: contents;
}

.chessBoard-row:nth-child(even) .chessBoard-space:nth-child(even) {
  background-color: var(--light-tile-color);
}

.chessBoard-row:nth-child(odd) .chessBoard-space:nth-child(odd) {
  background-color: var(--light-tile-color);
}

.chessBoard-row:nth-child(odd) .chessBoard-space:nth-child(even) {
  background-color: var(--dark-tile-color);
}

.chessBoard-row:nth-child(even) .chessBoard-space:nth-child(odd) {
  background-color: var(--dark-tile-color);
}

.chessBoard-space--clue {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 12px;
  color: var(--font-color);
}

.chessBoard-space {
  position: relative;
  height: 6rem;
  width: 6rem;
  border: 1px solid var(--font-color);
}

.chessBoard-space-inner {
  height: calc(100% - 26px);
  width: calc(100% - 26px);
  border: 6px solid rgba(0, 0, 0, 0);
  margin: -1px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.chessBoard-space-inner--error {
  height: calc(100% - 26px);
  width: calc(100% - 26px);
  border: 6px solid var(--error-color);
}

@media (max-width: 600px) {
  .chessBoard-space {
    height: 4rem;
    width: 4rem;
  }

}

.chessBoard-space--clue {
  position: absolute;
  font-size: 1rem;
  font-weight: 600;
  top: 0.15rem;
  left: 0.4rem;
}

.chessBoard-space--option {
  background-color: var(--primary-action-color) !important;
}

.chessBoard-space--target {
  background-color: var(--secondary-action-color) !important;
}

.letter {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
}

.chessBoard-space-inner--error .letter {
  color: var(--error-color);
}

.letter-blank {
  cursor: default;
}

.letter--dragging {
  //background-color: rgba(255,255,255,.7);
}

@media (max-width: 600px) {
  .chessBoard-space {
    position: relative;
    height: 4rem;
    width: 4rem;
    border: 1px solid var(--font-color);
  }
}