/* Reset */
a {
  text-decoration: none;
}

/* Import font */
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');

/* Colors and font */
:root {
  --primary-background-color: rgb(237, 235, 232);
  --primary-action-color: rgba(10, 193, 157, 1);
  --secondary-action-color: rgba(10, 193, 157, .8);
  --error-color: #ff4d40;
  --primary-font: 'Work Sans', sans-serif;
  --secondary-font: 'Space Mono', monospace;
  --light-tile-color: #f0f0f0;
  --dark-tile-color: #b0b0b0;
  --active-piece-color: var(--primary-action-color);
  --future-piece-color: rgba(10, 193, 157, 0.5);
  --past-piece-color: rgba(10, 193, 157, 0.2);
  --font-color: #202122;
}

html {
  height: calc(100vh - env(safe-area-inset-bottom));
  overflow: hidden;
}

/* Basic styles */
body {
  font-family: var(--primary-font);
  background-color: var(--primary-background-color);
  color: var(--font-color);
  margin-top: env(safe-area-inset-top);
  overflow: hidden;
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

.App {
  min-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.App-main {
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 64px - 129px);
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  height: 64px;
}

.App-link {
  color: #61dafb;
}

@media (max-width: 820px) {
  .App-title--extended {
    display: none;
  }
}

.App-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: #202122;

}

.App-footer {
  border-top: 1px solid black;
  padding-top: 16px;
  padding-bottom: env(safe-area-inset-bottom);
  width: 100%;
  height: 96px;
}

.App-footer-attribution {
  margin-right: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: calc(2vmin);
  color: var(--font-color);
}

.App-logo {
}
.App-help {
}
