@media (min-width: 821px) {
  .clueList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    overflow-y: auto;
    overflow-x: hidden;
    color: #333;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .clueButton {
    display: none !important;
  }
}

@media (max-width: 820px) {
  .clueList {
    display: none !important;
  }

  .clueButton {
    display: block;
    margin: 0 1rem;
  }
}