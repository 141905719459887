.piecesList {
  display: flex;
  flex-direction: row;
  width: 48rem;
  font-size: 8rem;
  justify-content: space-between;
  user-select: none;
  background-color: var(--primary-background-color);
}
@media (max-width: 820px) {
  .piecesList {
    justify-content: space-around;
    width: 100vw;
    font-size: 4rem;
  }
}

.piece {
}

.piece--used {
  color: var(--past-piece-color)
}

.piece--selected {
  color: var(--primary-action-color)
}
