@import '../styles/breakpoints';
@import '../styles/spacing';

$perspective: 12;
$perspectiveSteps: 2;

.parallax {
    perspective: #{$perspective}px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;

    @include lgUp {
        //left: $sideNavWidth;
        //width: calc(100vw - #{$sideNavWidth});
    }
}

.parallax-layer-#{0} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.parallax-layer-#{1} {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ($perspectiveSteps * -1px)
    scale(#{1 + $perspectiveSteps / $perspective});
}

@for $i from 2 through 5 {
    .parallax-layer-#{$i} {
        position: absolute;
        z-index: -#{$i};
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateZ($perspectiveSteps * $i * -1px)
        scale(#{1 + ($perspectiveSteps * $i) / $perspective});
    }
}
